























import { defineComponent, getCurrentInstance, SetupContext } from '@vue/composition-api'

import ArrowIcon from './ArrowIcon.vue'
import { AccordionItemProps } from './AccordionItem.contracts'
import { accordionItemProps, useAccordionItem } from './AccordionItem.hooks'

export const AccordionItem = defineComponent({
  name: 'AccordionItem',
  components: { ArrowIcon },
  props: accordionItemProps,
  emits: ['click'],

  setup (props: AccordionItemProps, ctx: SetupContext) {
    return { ...useAccordionItem(props, ctx.emit, getCurrentInstance()) }
  }
})

export default AccordionItem
